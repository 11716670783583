import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const IconWrap = styled.div`
  margin-right: 1.6rem;
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const IconMessageWrap = styled.div`
  display: flex;
  align-items: center;

  h5 {
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2rem;
    margin: 0;
    margin-bottom: 0.4rem;
  }

  p {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.secondary};
    margin: 0;
  }
`;

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    max-width: 40rem;
    width: calc(100% - 4rem);
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);

    .Toastify__toast {
      border: 0.1rem solid ${({ theme }) => theme.secondary}33;
      box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
      border-radius: ${({ theme }) => theme.borderRadius.br1};
      margin-bottom: 1.6rem;
      padding: 0.6rem 1.6rem 0.6rem 1rem;
      display: flex;
      align-items: center;
    }

    .Toastify__toast-body {
      margin: none !important;
      padding: none !important;
    }

    .Toastify__progress-bar {
    }
    .Toastify__close-button {
      align-self: center;
      margin-right: 8px;
    }
    .Toastify__close-button > svg {
      height: 2.2rem;
      width: 2.2rem;
      /* fill: #c4c4c4; */
    }
  }

  @keyframes move-in {
    0% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .move-in {
    animation: move-in 0.4s ease-out both;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @keyframes move-out {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-200px);
    }
  }

  .move-out {
    animation: move-out 0.3s ease-in both;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;
