import { logo } from 'assets';
import { GetStartedButton } from 'components/getStartedButton/getStartedButton';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { CONTACT, GALLERY, ROOT, VINOGRADI, WINES, routes } from 'routes';
import { styled } from 'styled-components';
import { Disclaimer } from 'styles/text';

const FooterLink = styled(NavLink)`
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.secondary};
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    color: ${({ theme }) => theme.primary};
  }
`;

const FooterTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.secondary}33;
  padding: 5rem 0;

  img {
    height: 7rem;
  }

  @media (max-width: 800px) {
    img {
      height: 6rem;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const FooterMiddle = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem 5rem;
  flex-wrap: wrap;
  border-top: 1px solid ${({ theme }) => theme.secondary}33;
  padding: 2rem 0;
`;

const FooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.secondary}33;
  padding: 2rem 0;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  padding: 0 10rem;
  max-width: 164rem;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: 0 3rem;
  }

  @media (max-width: 800px) {
    padding: 0 3rem;
  }
`;

export const Footer = () => {
  const date = new Date().getFullYear();
  const { t } = useTranslation('layout');

  return (
    <FooterWrapper>
      <FooterTop>
        <Link to={ROOT}>
          <img src={logo} alt='Vina Fachin' />
        </Link>
        <GetStartedButton />
      </FooterTop>
      <FooterMiddle>
        <FooterLink to={ROOT}>{t('home')}</FooterLink>
        <FooterLink to={WINES}>{t('wines')}</FooterLink>
        <FooterLink to={VINOGRADI}>{t('vineyards')}</FooterLink>
        <FooterLink to={GALLERY}>{t('gallery')}</FooterLink>
        <FooterLink to={CONTACT}>{t('contact')}</FooterLink>
      </FooterMiddle>
      <FooterBottom>
        <Disclaimer $m='0'>
          © Copyright 2023 - {date} | Vina Fachin | All Rights Reserved
        </Disclaimer>
      </FooterBottom>
    </FooterWrapper>
  );
};

export const footerLinks = routes;
