import { logo, logoWhite } from 'assets';
import { DropdownMenu } from 'components/dropdownMenu/dropdownMenu';
import { dropdownMenuLanguages } from 'components/dropdownMenu/dropdownMenuData';
import { DropdownMenuLanguages } from 'components/dropdownMenu/dropdownMenuLanguages';
import { pathname, useOnClickOutside } from 'helpers';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CONTACT, GALLERY, ROOT, VINOGRADI, WINES } from 'routes';
import { Div } from 'styles';
import { MenuIcon } from './menuIcon';
import {
  NavStyled,
  NavigationLink,
  NavigationStyled,
} from './navigation.styled';

export const Navigation = () => {
  const [sticky, setSticky] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 50 ? setSticky(true) : setSticky(false);
  };

  const handleMenu = () => setDropdown((prevState) => !prevState);
  const handleMenu2 = () => setDropdown2((prevState) => !prevState);

  useEffect(() => {
    if (dropdown) {
      handleMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useOnClickOutside(dropdownRef, () => dropdown && setDropdown(false));
  useOnClickOutside(dropdownRef2, () => dropdown2 && setDropdown2(false));

  const { t, i18n } = useTranslation('layout');

  const currentLanguage = i18n.language;

  const lang = dropdownMenuLanguages.find(
    ({ code }) => code === currentLanguage
  );

  useEffect(() => {
    if (dropdown2) {
      handleMenu2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <NavStyled
      $sticky={sticky}
      initial={{ opacity: 0, marginTop: -100 }}
      animate={{ opacity: 1, marginTop: 0 }}
      exit={{ opacity: 0, marginTop: -100 }}
      transition={{
        duration: 0.2,
        type: 'ease',
      }}
    >
      <nav>
        <NavigationStyled $sticky={sticky}>
          <Link to={ROOT}>
            <img src={sticky ? logo : logoWhite} alt='Grub concierge' />
          </Link>
          <Div $flex $center $gap='30px' $gapSM='20px'>
            <Div $desktop $flex $center $gap='30px' $gapSM='20px'>
              <NavigationLink to={ROOT} $sticky={sticky}>
                {t('home')}
              </NavigationLink>
              <NavigationLink to={WINES} $sticky={sticky}>
                {t('wines')}
              </NavigationLink>
              <NavigationLink to={VINOGRADI} $sticky={sticky}>
                {t('vineyard')}
              </NavigationLink>
              <NavigationLink to={GALLERY} $sticky={sticky}>
                {t('gallery')}
              </NavigationLink>
              <NavigationLink to={CONTACT} $sticky={sticky}>
                {t('contact')}
              </NavigationLink>
            </Div>
            <Div $mobile $flex $center $relative ref={dropdownRef}>
              <MenuIcon
                isOpen={dropdown}
                onClick={handleMenu}
                sticky={sticky}
              />
              <DropdownMenu
                isOpen={dropdown}
                setIsOpen={setDropdown}
                location={pathname}
              />
            </Div>

            <Div $flex $center $relative ref={dropdownRef2}>
              <img
                style={{ width: 30, cursor: 'pointer' }}
                src={lang?.flag}
                alt=''
                onClick={handleMenu2}
              />
              <DropdownMenuLanguages
                isOpen={dropdown2}
                setIsOpen={setDropdown2}
                location={pathname}
              />
            </Div>
          </Div>
        </NavigationStyled>
      </nav>
    </NavStyled>
  );
};
