import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export const DropdownStyled = styled(motion.div)`
  transform-origin: top right;
  width: 20rem;
  padding: 1rem 2.5rem;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  background: ${({ theme }) => theme.white};
  border: 0.1rem solid ${({ theme }) => theme.secondary}33;
  position: absolute;
  right: 0;
  top: 6rem;
  overflow: hidden;
  z-index: 10;

  /* @media (max-width: 800px) {
    width: 30rem;
  } */

  @media (max-width: 1000px) and (orientation: landscape) {
    width: 35rem;
  }

  max-height: calc(100vh - 10rem);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const NavLinkStyled = styled(NavLink)<{ $last?: boolean }>`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.secondary};
  margin: 0;
  display: block;
  line-height: 5rem;
  transition: color 0.2s ease;

  ${({ theme, $last }) =>
    !$last && `border-bottom: 1px solid ${theme.secondary}33;`}

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    color: ${({ theme }) => theme.primary};
  }

  transition: 0.3s ease;

  @media (max-width: 800px) {
    font-size: 2rem;
    line-height: 6rem;
  }

  @media (max-height: 580px) {
    font-size: 1.8rem;
    line-height: 5rem;
  }
`;
