import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { App } from './App';
import './i18n';
import './index.css';
import { theme } from './styles';
import './styles/global';
import Global from './styles/global';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <Global />
    <HashRouter>
      <App />
    </HashRouter>
  </ThemeProvider>
);
