import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface TextType {
  $m?: string;
  $mt?: string;
  $mb?: string;
  $mr?: string;
  $ml?: string;
  $center?: boolean;
  $right?: boolean;
  $fontSize?: string;
  $lineHeight?: string;
  $color?: string;
}

export const H1 = styled(motion.h1)<TextType>`
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 5.4rem;
  margin: 0;
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
    ${({ $color }) => $color && `color: ${$color}`};
  }

  @media (max-width: 800px) {
    font-size: 4.5rem;
    line-height: 5.3rem;
    margin-bottom: 3rem;
  }

  @media (max-width: 500px) {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 2rem;
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $fontSize }) => $fontSize && `font-size: ${$fontSize};`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
  ${({ $color }) => $color && `color: ${$color}`};
`;

export const H2 = styled(motion.h2)<TextType>`
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.2rem;
  margin: 0;
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 800px) {
    font-size: 4rem;
    line-height: 4.5rem;
    margin-bottom: 3rem;
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;

export const H3 = styled(motion.h3)<TextType>`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.6rem;
  margin: 0;
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;

export const H4 = styled(motion.h4)<TextType>`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 500px) {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;

export const H5 = styled(motion.h5)<TextType>`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin: 0;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;

export const P = styled(motion.p)<TextType>`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin: 0;
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;

export const Li = styled(motion.li)<TextType>`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin: 0;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;

export const Disclaimer = styled(motion.p)<TextType>`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 0;
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;

export const A = styled(Link)<TextType>`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin: 0;
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondary};
  span {
    color: ${({ theme }) => theme.primary};
  }

  ${({ $m }) => $m && `margin: ${$m};`}
  ${({ $mt }) => $mt && `margin-top: ${$mt};`}
  ${({ $mb }) => $mb && `margin-bottom: ${$mb};`}
  ${({ $ml }) => $ml && `margin-left: ${$ml};`}
  ${({ $mr }) => $mr && `margin-right: ${$mr};`}
  ${({ $center }) => $center && `text-align: center;`}
  ${({ $right }) => $right && `text-align: right;`}
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight};`}
`;
