import { Outlet } from 'react-router';
import { Animation } from '../';
import { Footer } from 'components/footer/footer';

export const Layout = () => {
  return (
    <>
      <Animation key='layout-outlet'>
        <Outlet />
      </Animation>
      <Footer key='layout-footer' />
    </>
  );
};
