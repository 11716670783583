import { vinogradi1, vinogradi2, vinogradiHero } from 'assets';
import { AnimatedDiv } from 'components';
import { useTranslation } from 'react-i18next';
import {
  Div,
  H1,
  H2,
  Img,
  P,
  PageWrapper,
  WrapperCenterSimpler,
  theme,
} from 'styles';

export const Vinogradi = () => {
  const { t } = useTranslation('vineyard');
  return (
    <>
      <Div
        $background={vinogradiHero}
        $backgroundOverlay
        $width='100%'
        $height='60vh'
        $heightSM='50vh'
        $flex
        $center
        style={{ backgroundPosition: 'center top' }}
      >
        <AnimatedDiv>
          <H1 $color={theme.white} $fontSize='7rem'>
            {t('title')}
          </H1>
        </AnimatedDiv>
      </Div>
      <PageWrapper>
        <>
          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem' $reverse>
            <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv $flexSM $centerSM>
                <Img
                  src={vinogradi1}
                  alt=''
                  $width={'100%'}
                  $widthSM={'100%'}
                />
              </AnimatedDiv>
            </Div>
            <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H2>
                  <span>{t('subtitle1')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('text1')}</P>
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>

          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem'>
            <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H2>
                  <span>{t('subtitle2')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('text2')}</P>
              </AnimatedDiv>
            </Div>
            <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv $flexSM $centerSM>
                <Img
                  src={vinogradi2}
                  alt=''
                  $width={'100%'}
                  $widthSM={'100%'}
                />
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>
        </>
      </PageWrapper>
    </>
  );
};
