import { contact, contactHero } from 'assets';
import { AnimatedDiv, Separator } from 'components';
import { useTranslation } from 'react-i18next';
import {
  Disclaimer,
  Div,
  H1,
  H4,
  H5,
  Img,
  P,
  PageWrapper,
  theme,
} from 'styles';

export const Contact = () => {
  const { t } = useTranslation('kontakt');
  return (
    <>
      <Div
        $background={contactHero}
        $backgroundOverlay
        $width='100%'
        $height='60vh'
        $heightSM='50vh'
        $flex
        $center
      >
        <AnimatedDiv>
          <H1 $color={theme.white} $fontSize='7rem'>
            {t('title')}
          </H1>
        </AnimatedDiv>
      </Div>
      <PageWrapper>
        <Div $flex $gap='30px' $columnSM $alignCenter>
          <Div $width={'60%'} $widthMD={'60%'} $widthSM={'100%'}>
            <AnimatedDiv>
              <H1>{t('subtitle')}</H1>
              <Div $maxWidth='50rem'>
                <P $mb='24'>
                  {t('text1')}
                  <br />
                  <br />
                  {t('text2')}
                </P>
              </Div>
            </AnimatedDiv>
          </Div>
          <Div $width={'40%'} $widthMD={'40%'} $widthSM={'100%'}>
            <AnimatedDiv>
              <Disclaimer $m='0'>{t('phone')}</Disclaimer>
              <a href={`tel: +385919133400`} target='_blank' rel='noreferrer'>
                <H5 $mb='20'>+385919133400 Natali</H5>
              </a>
              <Disclaimer $m='0'>{t('email')}</Disclaimer>
              <a
                href={`mailto: fachind@hotmail.com`}
                target='_blank'
                rel='noreferrer'
              >
                <H5 $mb='20'>fachind@hotmail.com</H5>
              </a>

              <Disclaimer $m='0'>{t('address')}</Disclaimer>
              <H5 $mb='20'>Rodeli 77a , 52424 Motovun</H5>

              <Disclaimer $m='0'>{t('social')}</Disclaimer>
              <a
                href={`https://www.facebook.com/vinafachin/`}
                target='_blank'
                rel='noreferrer'
              >
                <H5 $mb='0'>Facebook</H5>
              </a>

              <a
                href={`https://www.instagram.com/vinafachin/`}
                target='_blank'
                rel='noreferrer'
              >
                <H5 $mb='20'>Instagram</H5>
              </a>
            </AnimatedDiv>
          </Div>
        </Div>

        <Div $mt='50px' $mb='50px'>
          <iframe
            // style='border: 0;'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805.1735960212663!2d13.807598476266413!3d45.3251168710719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477ca6da3a22ef41%3A0x79ecf80e833f60fd!2sVina%20Fachin!5e0!3m2!1sen!2srs!4v1697383469448!5m2!1sen!2srs'
            width='100%'
            height='400'
            // allowfullscreen='allowfullscreen'
          ></iframe>
        </Div>
        {/* <AnimatedDiv>
        <H3 $mb='1rem'>
          <span>Have a</span> question?
        </H3>
        <P $mb='0'>
          Please contact us with this form. We will get in touch with you
          shortly.
        </P>
        <FrameWrapper
          height={520}
          title='contact-dorm'
          src={`${WIDGET_FORM_URL}${contactFormId}`}
          id={contactFormId}
        ></FrameWrapper>
      </AnimatedDiv> */}
        <Separator />
      </PageWrapper>
    </>
  );
};
