import { AnimatePresence } from 'framer-motion';
import { Div } from '../../styles';
import { DropdownStyled, NavLinkStyled } from './dropdownMenu.styled';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { CONTACT, GALLERY, ROOT, VINOGRADI, WINES } from 'routes';

const variants = {
  initial: {
    opacity: 0,
    transform: 'scale(1, 0)',

    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
  animate: {
    opacity: 1,
    transform: 'scale(1, 1)',
  },

  exit: {
    opacity: 0,
    transform: 'scale(1, 0)',
    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
};

interface DropdownMenuType {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  location: string;
}

export const DropdownMenu: FC<DropdownMenuType> = ({
  isOpen,
  setIsOpen,
  location,
}) => {
  const { t } = useTranslation('layout');

  return (
    <AnimatePresence>
      {isOpen && (
        <DropdownStyled
          key='DropdownMenu'
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <Div>
            <NavLinkStyled
              to={ROOT}
              onClick={() => location !== ROOT && setIsOpen(false)}
            >
              {t('home')}
            </NavLinkStyled>
            <NavLinkStyled
              to={WINES}
              onClick={() => location !== WINES && setIsOpen(false)}
            >
              {t('wines')}
            </NavLinkStyled>
            <NavLinkStyled
              to={VINOGRADI}
              onClick={() => location !== VINOGRADI && setIsOpen(false)}
            >
              {t('vineyard')}
            </NavLinkStyled>
            <NavLinkStyled
              to={GALLERY}
              onClick={() => location !== GALLERY && setIsOpen(false)}
            >
              {t('gallery')}
            </NavLinkStyled>
            <NavLinkStyled
              to={CONTACT}
              onClick={() => location !== CONTACT && setIsOpen(false)}
              $last
            >
              {t('contact')}
            </NavLinkStyled>
          </Div>
        </DropdownStyled>
      )}
    </AnimatePresence>
  );
};
