import { Contact } from 'pages/Contact/contact';
import { GalleryPage } from 'pages/Gallery/gallery';
import { Vinogradi } from 'pages/Vinogradi/Vinogradi';
import { Wines } from 'pages/Wines/wines';
import { Home } from '../pages';

export const PARTNERS = '/partners';
export const CLIENTS = '/client';
export const PRICING = '/pricing';
export const PRICING_START = '/pricing/start';
export const PAYMENT = '/payment';
export const THANK_YOU = '/thank-you';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';

export const ROOT = '/';
export const CONTACT = '/contact-us';
export const FAQ = '/faq';
export const GALLERY = '/gallery';
export const VINOGRADI = '/vinogradi';
export const WINES = '/wines';
export const NOT_FOUND = '/*';

export const routes = [
  {
    path: ROOT,
    Page: Home,
  },

  {
    path: WINES,
    Page: Wines,
  },
  {
    path: VINOGRADI,
    Page: Vinogradi,
  },
  {
    path: GALLERY,
    Page: GalleryPage,
  },
  {
    path: CONTACT,
    Page: Contact,
  },
];
