import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  unitDisplay: 'short',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const ScrollToTop = ({ children }: { children: any }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, [location.pathname]);
  return children;
};

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const contactPhone = '(855) GRUBCON';
export const contactEmail = 'info@grubcon.com';

export const contactFormId = '199e0PqCCXHjcKxhBgL8';
export const partnerBookingId = 'quR9VlJ1s5KcDE09VmEJ';
export const clientBookingId = 'n4Wei7UQfs3hDtb3xgi8';
export const pricingBookingId = 'EHlB9C4VaDRRNpfp6x8s';

export const WIDGET_FORM_URL = 'https://api.leadconnectorhq.com/widget/form/';
export const WIDGET_BOOKING_URL =
  'https://api.leadconnectorhq.com/widget/booking/';

export const pathname = window.location.pathname;

export const setDocumentTitle = (pageTitle: string) =>
  (document.title = `${pageTitle}`);
