import styled from 'styled-components';
import { motion } from 'framer-motion';

/* @media (min-width: 3000px) {
    max-width: 1940px;
  }

  @media (min-width: 3500px) {
    max-width: 80%;
    ${({ $home }) => !$home && `padding-top: 300px;`}
  } */

export const PageWrapper = styled.div<{
  centeredContent?: boolean;
  $home?: boolean;
}>`
  width: 100%;
  padding: 0 10rem;
  max-width: 164rem;
  margin: 0 auto;
  position: relative;
  padding-bottom: 15rem;
  min-height: calc(100vh + 5rem);

  ${({ centeredContent }) =>
    centeredContent &&
    `
   display: flex;
  align-items: center;
  `}
  ${({ $home }) => !$home && `padding-top: 15rem;`}

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: 0 3rem;
    padding-bottom: 10rem;
    ${({ $home }) => !$home && `padding-top: 10rem;`}
  }

  @media (max-width: 800px) {
    padding: 0 3rem;
    padding-bottom: 10rem;
    ${({ $home }) => !$home && `padding-top: 10rem;`}
  }
`;

export const WrapperCenter = styled.div`
  width: 100%;
  padding: 7.5rem 0;

  @media (max-width: 800px) {
    padding: 3rem 0;
  }
`;

export const WrapperCenterSimpler = styled.div<{
  $gap?: string;
  $gapSM?: string;
  $reverse?: boolean;
}>`
  width: 100%;
  padding: 7.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ $gap }) => ($gap ? $gap : '10rem')};

  @media (max-width: 800px) {
    padding: 3rem 0;
    gap: 5rem;
    flex-direction: column;
    ${({ $reverse }) => $reverse && ' flex-direction: column-reverse;'}
  }
`;

export const WrapperCenterSimplerHome = styled.div`
  position: relative;
  padding: 7.5rem 0;
  display: flex;
  align-items: center;
  gap: 5rem;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 800px) {
    gap: 3rem;
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media (max-height: 700px) {
    padding-top: 5rem;
  }

  @media (max-width: 500px) {
    gap: 0;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    padding-top: 8rem;
    flex-direction: row;
  }
`;

export interface DivStyled {
  $desktop?: boolean;
  $mobile?: boolean;
  $box?: boolean;
  $m?: string;
  $mt?: string;
  $mb?: string;
  $ml?: string;
  $mr?: string;
  $mSM?: string;
  $mtSM?: string;
  $mbSM?: string;
  $mlSM?: string;
  $mrSM?: string;
  $width?: string;
  $height?: string;
  $maxWidth?: string;
  $minWidth?: string;
  $widthMD?: string;
  $alignEnd?: boolean;
  $alignCenter?: boolean;
  $relative?: boolean;
  $pointer?: boolean;
  $gap?: string;
  $between?: boolean;
  $center?: boolean;
  $flexNone?: boolean;
  $flex?: boolean;
  $justifyEnd?: boolean;
  $column?: boolean;
  $wrap?: boolean;
  $textAlignLeft?: boolean;
  $textAlignRight?: boolean;
  $textAlignCenter?: boolean;
  $justifyCenter?: boolean;
  $p?: string;
  $pSM?: string;
  $pMD?: string;
  $minWidthSM?: string;
  $heightSM?: string;
  $widthSM?: string;
  $maxWidthSM?: string;
  $reverseSM?: boolean;
  $columnSM?: boolean;
  $gapSM?: string;
  $centerSM?: boolean;
  $flexSM?: boolean;
  $gapMD?: string;
  $background?: any;
  $backgroundOverlay?: any;
  viewport?: any;
  variants?: any;
}

export const Div = styled(motion.div)<DivStyled>`
  ${({ $box, theme }) =>
    $box &&
    `
   background: ${theme.white};
   border: 0.1rem solid ${theme.secondary}33;
  box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
  border-radius: ${theme.borderRadius.br1};
  padding: 2rem;
`}

  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt};
  margin-bottom: ${(props) => props.$mb};
  margin-left: ${(props) => props.$ml};
  margin-right: ${(props) => props.$mr};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};

  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}`};
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}`};
  ${({ $background }) =>
    $background &&
    `
  
  background: url(${$background});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-attachment: fixed;

  `};

  ${({ $backgroundOverlay, $background }) =>
    $backgroundOverlay &&
    `
  
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${$background});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-attachment: fixed;


  `};

  padding: ${(props) => props.$p};

  ${({ $flex }) => $flex && 'display: flex'};
  ${({ $flexNone }) => $flexNone && 'flex: none'};
  ${({ $center }) => $center && 'align-items: center; justify-content:center;'};
  ${({ $between }) => $between && 'justify-content: space-between;'};
  ${({ $gap, $flex }) => $flex && $gap && `gap:${$gap}`};
  ${({ $pointer }) => $pointer && 'cursor: pointer'};
  ${({ $relative }) => $relative && 'position: relative'};
  ${({ $alignCenter }) => $alignCenter && 'align-items: center'};
  ${({ $alignEnd }) => $alignEnd && 'align-items: flex-end'};
  ${({ $justifyEnd }) => $justifyEnd && 'justify-content: flex-end'};
  ${({ $justifyCenter }) => $justifyCenter && 'justify-content: center'};
  ${({ $textAlignCenter }) => $textAlignCenter && 'text-align: center'};
  ${({ $textAlignRight }) => $textAlignRight && 'text-align: right'};
  ${({ $textAlignLeft }) => $textAlignLeft && 'text-align: left'};

  ${({ $column }) => $column && 'flex-direction: column'};
  ${({ $wrap }) => $wrap && 'flex-wrap: wrap'};

  @media (max-width: 1450px) {
    width: ${(props) => props.$widthMD};
    padding: ${(props) => props.$pMD};
    ${({ $gapMD }) => $gapMD && `gap: ${$gapMD}`};
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    height: auto;
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM};
    margin-bottom: ${(props) => props.$mbSM};
    margin-left: ${(props) => props.$mlSM};
    margin-right: ${(props) => props.$mrSM};
    width: ${(props) => props.$widthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    min-width: ${(props) => props.$minWidthSM};

    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
      $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};
  }

  @media (max-width: 800px) {
    height: auto;
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    min-width: ${(props) => props.$minWidthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
      $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};
  }

  ${({ $desktop }) =>
    $desktop &&
    `
    @media (max-width: 800px) {
    display: none;
  }

  `};

  ${({ $mobile }) =>
    $mobile &&
    `
    @media (min-width: 800px) {
    display: none;
  }
  
 

  `};
`;

export const MotionDiv = styled(motion.div)``;

export const Img = styled.img<{
  $m?: string;
  $mt?: string;
  $mb?: string;
  $ml?: string;
  $mr?: string;
  $mSM?: string;
  $mtSM?: string;
  $mbSM?: string;
  $mlSM?: string;
  $mrSM?: string;
  $widthSM?: string;
  $heightSM?: string;
  $maxWidthSM?: string;
  $maxHeightSM?: string;
  $height?: string;
  $width?: string;
  $maxWidth?: string;
  $maxHeight?: string;
  $borderRadius?: boolean;
}>`
  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt}px;
  margin-bottom: ${(props) => props.$mb}px;
  margin-left: ${(props) => props.$ml}px;
  margin-right: ${(props) => props.$mr}px;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
  max-height: ${(props) => props.$maxHeight};
  border-radius: ${({ theme, $borderRadius }) =>
    $borderRadius && `border-radius: ${theme.borderRadius.br1};`};

  @media (max-width: 1000px) and (orientation: landscape) {
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    height: ${(props) => props.$heightSM};
    max-width: ${(props) => props.$maxWidthSM};
  }

  @media (max-width: 800px) {
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    max-width: ${(props) => props.$maxWidthSM};
    max-height: ${(props) => props.$maxHeightSM};
  }
`;

export const Icon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  @media (max-width: 800px) {
    width: 3rem;
    height: 3rem;
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const SocialIcon = styled.div`
  svg {
    width: 4.2rem;
    height: 4.2rem;
  }

  @media (max-width: 800px) {
    svg {
      width: 5rem;
      height: 5rem;
    }
  }
`;

export const Button = styled.button<{
  $w?: string;
  $p?: string;
  $selected?: boolean;
  $negative?: boolean;
  $green?: boolean;
  $m?: string;
  $mSM?: string;
  $maxWidth?: string;
  $maxWidthSM?: string;
  $secondary?: boolean;
}>`
  font-family: 'Montserrat';
  position: relative;
  height: 4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.primary};
  border: none;
  width: ${({ $w }) => ($w ? $w : '18.0rem')};
  overflow: hidden;
  transition: all 100ms ease-in;
  font-weight: bold;
  z-index: 1;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
  ${({ $p }) => $p && 'padding: 0 3rem;'};

  &:active {
    transform: translateY(0.3rem);
  }

  &:hover {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.primary};
  }

  ${({ $negative, theme }) =>
    $negative &&
    `
    border:  0.1rem solid ${theme.secondary}33;
    box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
    color: ${theme.primary};

  `}

  ${({ $secondary, theme }) =>
    $secondary &&
    `
    color: ${theme.secondary};
    background: ${theme.white};
    box-shadow: 0 0 0 0.1rem ${theme.secondary};

    &:hover {
      color: ${theme.white};
      background: ${theme.secondary};
    }
  `}

  ${({ $selected, theme, $secondary }) =>
    $selected &&
    `
    color: ${theme.white};
    background: ${$secondary ? theme.secondary : theme.primary};
  `}

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.3;
      cursor: not-allowed;
  `}

  margin: ${(props) => props.$m};
  max-width: ${(props) => props.$maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.$mSM};
    max-width: ${(props) => props.$maxWidthSM};
  }
`;

export const SliderPaginationCircle = styled.div<{ $selected: boolean }>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  /* margin: 1rem; */
  cursor: pointer;

  @media (max-width: 800px) {
    width: 1.2rem;
    height: 1.2rem;
  }

  ${({ $selected, theme }) => $selected && `background: ${theme.primary};`}
  ${({ $selected, theme }) =>
    !$selected && `border: 1px solid ${theme.secondary}33;`}
`;

export const Gradient1 = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 50%;
  left: 0;
  right: 0;
  background: radial-gradient(
    circle at 55% -10%,
    rgba(127, 21, 61, 0.3) 0%,
    rgba(255, 255, 255, 0) 30%
  );
  z-index: -1000;

  @media (max-width: 800px) {
    background: radial-gradient(
      circle at 55% -5%,
      rgba(127, 21, 61, 0.3) 0%,
      rgba(255, 255, 255, 1) 20%
    );
  }
`;

export const Gradient2 = styled(motion.div)`
  position: fixed;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(
    circle at 45% 110%,
    rgba(127, 21, 61, 0.3) 0%,
    rgba(255, 255, 255, 0) 30%
  );
  z-index: -1000;

  @media (max-width: 800px) {
    background: radial-gradient(
      circle at 45% 105%,
      rgba(127, 21, 61, 0.3) 0%,
      rgba(255, 255, 255, 1) 20%
    );
  }
`;

export const SliderPlaceholder = styled.div`
  width: 100%;
  position: relative;

  > span {
    visibility: hidden;
  }

  > div {
    position: absolute;
    top: 0;
  }
`;

export const SeparatorBorderCMS = styled.div<{ $m?: string }>`
  display: flex;
  align-items: center;
  gap: 5rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.secondary}33;
  ${({ $m }) => $m && `margin:${$m}`};
`;
