import de from '../../assets/icons/flags/svg/de.svg';
import gb from '../../assets/icons/flags/svg/gb.svg';
import hr from '../../assets/icons/flags/svg/hr.svg';
import it from '../../assets/icons/flags/svg/it.svg';

export const dropdownMenuLanguages = [
  {
    code: 'hr',
    flag: hr,
    name: 'Hrvatski',
  },
  {
    code: 'en',
    flag: gb,
    name: 'English',
  },
  {
    code: 'it',
    flag: it,
    name: 'Italy',
  },
  {
    code: 'de',
    flag: de,
    name: 'Germany',
  },
];
