import { PageWrapper, theme } from 'styles';

import { malvazija, muskat, pinot, rose, teran, vinaHero } from 'assets';
import { AnimatedDiv } from 'components';
import { useTranslation } from 'react-i18next';
import { Div, Img, P, WrapperCenterSimpler } from 'styles';
import { H1, H2, H4 } from 'styles/text';

export const Wines = () => {
  const { t } = useTranslation('wines');

  return (
    <>
      <Div
        $background={vinaHero}
        $backgroundOverlay
        $width='100%'
        $height='60vh'
        $heightSM='50vh'
        $flex
        $center
      >
        <AnimatedDiv>
          <H1 $color={theme.white} $fontSize='7rem'>
            {t('wines')}
          </H1>
        </AnimatedDiv>
      </Div>
      <PageWrapper>
        <>
          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem' $reverse>
            <Div $width={'40%'} $widthMD={'40%'} $widthSM={'100%'}>
              <Div $flexSM $centerSM $center $flex>
                <Img
                  src={malvazija}
                  alt=''
                  // $width={'100%'}
                  // $widthSM={'100%'}
                  $maxHeight='80vh'
                />
              </Div>
            </Div>
            <Div $width={'60%'} $widthMD={'60%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H4>{t('malvazija.type')}</H4>
              </AnimatedDiv>
              <AnimatedDiv>
                <H2>
                  <span>{t('malvazija.name')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('malvazija.info')}</P>
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>

          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem'>
            <Div $width={'60%'} $widthMD={'60%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H4>{t('teran.type')}</H4>
              </AnimatedDiv>
              <AnimatedDiv>
                <H2>
                  <span>{t('teran.name')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('teran.info')}</P>
              </AnimatedDiv>
            </Div>
            <Div $width={'40%'} $widthMD={'40%'} $widthSM={'100%'}>
              <Div $flexSM $centerSM $center $flex>
                <Img
                  src={teran}
                  alt=''
                  // $width={'100%'}
                  // $widthSM={'100%'}
                  $maxHeight='80vh'
                />
              </Div>
            </Div>
          </WrapperCenterSimpler>

          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem' $reverse>
            <Div $width={'40%'} $widthMD={'40%'} $widthSM={'100%'}>
              <Div $flexSM $centerSM $center $flex>
                <Img
                  src={rose}
                  alt=''
                  // $width={'100%'}
                  // $widthSM={'100%'}
                  $maxHeight='80vh'
                />
              </Div>
            </Div>
            <Div $width={'60%'} $widthMD={'60%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H4>{t('rose.type')}</H4>
              </AnimatedDiv>
              <AnimatedDiv>
                <H2>
                  <span>{t('rose.name')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('rose.info')}</P>
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>

          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem'>
            <Div $width={'60%'} $widthMD={'60%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H4>{t('muskat.type')}</H4>
              </AnimatedDiv>
              <AnimatedDiv>
                <H2>
                  <span>{t('muskat.name')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('muskat.info')}</P>
              </AnimatedDiv>
            </Div>
            <Div $width={'40%'} $widthMD={'40%'} $widthSM={'100%'}>
              <Div $flexSM $centerSM $center $flex>
                <Img
                  src={muskat}
                  alt=''
                  // $width={'100%'}
                  // $widthSM={'100%'}
                  $maxHeight='80vh'
                />
              </Div>
            </Div>
          </WrapperCenterSimpler>
          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem' $reverse>
            <Div $width={'40%'} $widthMD={'40%'} $widthSM={'100%'}>
              <Div $flexSM $centerSM $center $flex>
                <Img
                  src={pinot}
                  alt=''
                  // $width={'100%'}
                  // $widthSM={'100%'}
                  $maxHeight='80vh'
                />
              </Div>
            </Div>
            <Div $width={'60%'} $widthMD={'60%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H4>{t('pinot.type')}</H4>
              </AnimatedDiv>
              <AnimatedDiv>
                <H2>
                  <span>{t('pinot.name')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('pinot.info')}</P>
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>
        </>
      </PageWrapper>
    </>
  );
};
