import { Navigation } from 'components';
import { StyledToastContainer } from 'components/Toasts/ToastsStyled';
import 'react-toastify/dist/ReactToastify.min.css';
import { ScrollToTop } from 'helpers';
import { AppRouter } from 'routes';
import { Gradient1, Gradient2 } from 'styles';

export const App = () => {
  return (
    <div>
      <StyledToastContainer />

      <Navigation />
      <ScrollToTop>
        <AppRouter />
      </ScrollToTop>
      <Gradient1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.2,
          type: 'ease',
          delay: 0.4,
        }}
      />
      <Gradient2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.2,
          type: 'ease',
          delay: 0.8,
        }}
      />
    </div>
  );
};
