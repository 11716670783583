import { AnimatedDiv } from 'components/animation/animation';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes ldio-4ro331xvst8 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .ldio-4ro331xvst8 div {
    position: absolute;
    width: 4.698rem;
    height: 4.698rem;
    border: 0.522rem solid ${({ theme }) => theme.primary};
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-4ro331xvst8 div {
    animation: ldio-4ro331xvst8 0.5681818181818182s linear infinite;
    top: 5rem;
    left: 5rem;
  }
  .loadingio-spinner-rolling-t61fu8i5jyi {
    width: 10rem;
    height: 10rem;
    display: inline-block;
    overflow: hidden;
  }
  .ldio-4ro331xvst8 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-4ro331xvst8 div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
`;

export const FrameWrapper = ({
  title,
  height,
  src,
  id,
}: {
  title: string;
  height?: number;
  src: string;
  id: string;
}) => {
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <AnimatePresence>
      {loading && (
        <AnimatedDiv key='iframe_spinner'>
          <Spinner>
            <div className='loadingio-spinner-rolling-t61fu8i5jyi'>
              <div className='ldio-4ro331xvst8'>
                <div></div>
              </div>
            </div>
          </Spinner>
        </AnimatedDiv>
      )}

      <motion.div
        animate={{
          opacity: loading ? 0 : 1,
          y: loading ? 100 : 0,
          transition: {
            duration: 0.2,
            type: 'ease',
          },
        }}
      >
        <iframe
          id={id}
          title={title}
          src={src}
          onLoad={onLoad}
          scrolling='no'
          style={{
            width: '100%',
            height: height ? height : 1350,
            border: 'none',
          }}
        ></iframe>
      </motion.div>
    </AnimatePresence>
  );
};
