import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CONTACT } from 'routes';
import { Button } from 'styles';

export const GetStartedButton = () => {
  const { t } = useTranslation('layout');
  return (
    <Link to={CONTACT}>
      <Button $w='25rem'>{t('contact_us_button')}</Button>
    </Link>
  );
};
