// theme.ts
import 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    primary: string;
    secondary: string;
    white: string;
    size: {
      s4: string;
      s8: string;
      s12: string;
      s16: string;
      s20: string;
      s24: string;
      s28: string;
      s32: string;
      s36: string;
      s40: string;
    };
    transitions: {
      ease300ms: string;
    };
    transparency: {
      t10: string;
      t20: string;
      t30: string;
      t40: string;
      t50: string;
      t60: string;
      t70: string;
      t80: string;
      t90: string;
      t100: string;
    };
    shadows: {
      shadow1: string;
    };
    borders: {
      border1: string;
    };
    borderRadius: {
      br1: string;
    };
  }
}

export const theme = {
  primary: '#7f153d',
  secondary: '#737373',
  white: '#ffffff',
  size: {
    s4: '0.4rem',
    s8: '0.8rem',
    s12: '1.2rem',
    s16: '1.6rem',
    s20: '2rem',
    s24: '2.4rem',
    s28: '2.8rem',
    s32: '3.2rem',
    s36: '3.6rem',
    s40: '4rem',
  },
  transitions: {
    ease300ms: 'all 0.3s ease',
  },
  transparency: {
    t10: '1A',
    t20: '33',
    t30: '4D',
    t40: '66',
    t50: '80',
    t60: '99',
    t70: 'B3',
    t80: 'CC',
    t90: 'E6',
    t100: 'FF',
  },
  shadows: {
    shadow1: '0px 15px 30px rgba(0, 0, 0, 0.1)',
  },
  borders: {
    border1: '1px solid #7373734D',
  },
  borderRadius: {
    br1: '1rem',
  },
};
