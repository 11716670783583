import { styled } from 'styled-components';
import { theme } from 'styles';

export const SeparatorStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  opacity: 0.2;
  margin: 3rem 0;

  svg {
    flex: none;
  }

  svg {
    width: 6rem;
    height: 6rem;
  }
`;

export const SeparatorBorder = styled.div<{ $m?: string }>`
  display: flex;
  align-items: center;
  gap: 5rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.secondary};
  ${({ $m }) => $m && `margin:${$m}`};
`;

export const Separator = ({
  borderLeft,
  borderRight,
}: {
  borderLeft?: boolean;
  borderRight?: boolean;
}) => {
  return (
    <SeparatorStyled>
      {borderLeft && <SeparatorBorder />}
      <svg
        width='62'
        height='62'
        viewBox='0 0 62 62'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.9119 6.69505C12.9119 10.2823 10.0695 13.2007 6.57351 13.2007C3.07751 13.2007 0.235107 10.2975 0.235107 6.69505C0.235107 3.10785 3.07751 0.189453 6.57351 0.189453C10.0695 0.189453 12.9119 3.10785 12.9119 6.69505ZM30.9999 0.189453C27.5039 0.189453 24.6615 3.09265 24.6615 6.69505C24.6615 10.2823 27.5039 13.2007 30.9999 13.2007C34.4959 13.2007 37.3383 10.2975 37.3383 6.69505C37.3383 3.10785 34.4959 0.189453 30.9999 0.189453ZM55.4263 13.2007C58.9223 13.2007 61.7647 10.2975 61.7647 6.69505C61.7647 3.10785 58.9223 0.189453 55.4263 0.189453C51.9303 0.189453 49.0879 3.09265 49.0879 6.69505C49.0879 10.2823 51.9303 13.2007 55.4263 13.2007ZM6.57351 24.4943C3.07751 24.4943 0.235107 27.3975 0.235107 30.9999C0.235107 34.5871 3.07751 37.5054 6.57351 37.5054C10.0695 37.5054 12.9119 34.6023 12.9119 30.9999C12.9119 27.4127 10.0695 24.4943 6.57351 24.4943ZM30.9999 24.4943C27.5039 24.4943 24.6615 27.3975 24.6615 30.9999C24.6615 34.5871 27.5039 37.5054 30.9999 37.5054C34.4959 37.5054 37.3383 34.6023 37.3383 30.9999C37.3383 27.4127 34.4959 24.4943 30.9999 24.4943ZM55.4263 24.4943C51.9303 24.4943 49.0879 27.3975 49.0879 30.9999C49.0879 34.5871 51.9303 37.5054 55.4263 37.5054C58.9223 37.5054 61.7647 34.6023 61.7647 30.9999C61.7647 27.4127 58.9223 24.4943 55.4263 24.4943ZM6.57351 48.7991C3.07751 48.7991 0.235107 51.7023 0.235107 55.3047C0.235107 58.8919 3.07751 61.8103 6.57351 61.8103C10.0695 61.8103 12.9119 58.9071 12.9119 55.3047C12.9119 51.7175 10.0695 48.7991 6.57351 48.7991ZM30.9999 48.7991C27.5039 48.7991 24.6615 51.7023 24.6615 55.3047C24.6615 58.8919 27.5039 61.8103 30.9999 61.8103C34.4959 61.8103 37.3383 58.9071 37.3383 55.3047C37.3383 51.7175 34.4959 48.7991 30.9999 48.7991ZM55.4263 48.7991C51.9303 48.7991 49.0879 51.7023 49.0879 55.3047C49.0879 58.8919 51.9303 61.8103 55.4263 61.8103C58.9223 61.8103 61.7647 58.9071 61.7647 55.3047C61.7647 51.7175 58.9223 48.7991 55.4263 48.7991Z'
          fill={theme.secondary}
        />
      </svg>
      {borderRight && <SeparatorBorder />}
    </SeparatorStyled>
  );
};
