import { home1, home2, home3, home4, homeHero } from 'assets';
import { AnimatedDiv } from 'components';
import { useTranslation } from 'react-i18next';
import { Typewriter } from 'react-simple-typewriter';
import {
  Div,
  H1,
  H2,
  Img,
  P,
  PageWrapper,
  WrapperCenterSimpler,
  theme,
} from 'styles';

export const Home = () => {
  const { t } = useTranslation('home');
  return (
    <>
      <Div
        $background={homeHero}
        $backgroundOverlay
        $width='100%'
        $height='100vh'
        $heightSM='70vh'
        $flex
        $center
        $mb='100px'
      >
        <AnimatedDiv>
          <H1 $color={theme.white} $fontSize='7rem'>
            <Typewriter
              loop={1}
              words={[t('welcome_to'), t('vina_fachin')]}
              delaySpeed={1000}
            />
          </H1>
        </AnimatedDiv>
      </Div>
      <PageWrapper $home>
        <>
          <H2 $center>
            <span> {t('title')}</span>
          </H2>
          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem' $reverse>
            <Div $width={'55%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv $flexSM $centerSM>
                <Img src={home1} alt='' $width={'100%'} $widthSM={'100%'} />
              </AnimatedDiv>
            </Div>
            <Div $width={'45%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H2>
                  <span>{t('subtitle1')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('text1')}</P>
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>

          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem'>
            <Div $width={'45%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H2>
                  <span>{t('subtitle2')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('text2')}</P>
              </AnimatedDiv>
            </Div>
            <Div $width={'55%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv $flexSM $centerSM>
                <Img src={home2} alt='' $width={'100%'} $widthSM={'100%'} />
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>

          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem' $reverse>
            <Div $width={'55%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv $flexSM $centerSM>
                <Img src={home3} alt='' $width={'100%'} $widthSM={'100%'} />
              </AnimatedDiv>
            </Div>
            <Div $width={'45%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H2>
                  <span>{t('subtitle3')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('text3')}</P>
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>
          <WrapperCenterSimpler $gap='5rem' $gapSM='3rem'>
            <Div $width={'45%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv>
                <H2>
                  <span>{t('subtitle4')}</span>
                </H2>
              </AnimatedDiv>
              <AnimatedDiv>
                <P>{t('text4')}</P>
              </AnimatedDiv>
            </Div>
            <Div $width={'55%'} $widthMD={'50%'} $widthSM={'100%'}>
              <AnimatedDiv $flexSM $centerSM>
                <Img src={home4} alt='' $width={'100%'} $widthSM={'100%'} />
              </AnimatedDiv>
            </Div>
          </WrapperCenterSimpler>
        </>
      </PageWrapper>
    </>
  );
};
