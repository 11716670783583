import { AnimatePresence } from 'framer-motion';
import { Div, P } from '../../styles';
import { DropdownStyled } from './dropdownMenu.styled';
import { dropdownMenuLanguages } from './dropdownMenuData';
import { Dispatch, FC, SetStateAction } from 'react';
import i18next from 'i18next';

const variants = {
  initial: {
    opacity: 0,
    transform: 'scale(1, 0)',

    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
  animate: {
    opacity: 1,
    transform: 'scale(1, 1)',
  },

  exit: {
    opacity: 0,
    transform: 'scale(1, 0)',
    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
};

interface DropdownMenuType {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  location: string;
}

export const DropdownMenuLanguages: FC<DropdownMenuType> = ({
  isOpen,
  setIsOpen,
  location,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DropdownStyled
          key='DropdownMenu'
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <Div>
            {dropdownMenuLanguages.map((language, i) => {
              return (
                <Div
                  style={{ cursor: 'pointer' }}
                  key={i}
                  $flex
                  $alignCenter
                  $gap='10px'
                  onClick={() => {
                    i18next.changeLanguage(language.code);
                  }}
                  $m='20px 0'
                >
                  <img style={{ width: 30 }} src={language.flag} alt='' />
                  <P $m='0'>{language.name}</P>
                </Div>
              );
            })}
          </Div>
        </DropdownStyled>
      )}
    </AnimatePresence>
  );
};
