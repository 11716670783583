import { Layout } from 'components';
import { AnimatePresence } from 'framer-motion';
// import { NotFound404 } from 'pages/404/404';
import { Route, Routes, useLocation } from 'react-router';
import { routes } from './routeConstants';

export const AppRouter = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode='wait'>
      <Routes key={location.pathname} location={location}>
        <Route path='/' element={<Layout />}>
          {routes.map(({ path, Page }, i) => (
            <Route key={i} path={path} element={<Page />} />
          ))}
        </Route>

        {/* <Route path={NOT_FOUND} element={<NotFound404 />} /> */}
      </Routes>
    </AnimatePresence>
  );
};
