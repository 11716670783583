import { galleryHero } from 'assets';
import { AnimatedDiv } from 'components';
import { Div, H1, PageWrapper, theme } from 'styles';

import { useTranslation } from 'react-i18next';
import { ImageGallery } from 'react-image-grid-gallery';

const images = require.context('../../assets/images/gallery', true);
const imageList = images.keys().map((image) => images(image));

const imgs = imageList.map((image, index) => ({
  alt: index,
  src: image,
}));

const imagesArray = [...imgs];

export const GalleryPage = () => {
  const { t } = useTranslation('gallery');
  return (
    <>
      <Div
        $background={galleryHero}
        $backgroundOverlay
        $width='100%'
        $height='60vh'
        $heightSM='50vh'
        $flex
        $center
      >
        <AnimatedDiv>
          <H1 $color={theme.white} $fontSize='7rem'>
            {t('title')}
          </H1>
        </AnimatedDiv>
      </Div>
      <PageWrapper>
        <AnimatedDiv>
          <ImageGallery
            imagesInfoArray={imagesArray}
            columnWidth={400}
            gapSize={30}
          />
        </AnimatedDiv>
      </PageWrapper>
    </>
  );
};
